import React, { Suspense, lazy } from 'react';
// without lazy import
import SignOut from '../pages/ExtraPages/SignOut';
import TrackShipmentDetails from '../pages/TrackShipment/TrackShipmentDetails';
import MyProfile from '../pages/Profile/MyProfile/MyProfile';
import Layout2 from '../pages/Home/Layout2/Layout2';
import Agencies from '../pages/Agencies/Agencies';
import Carriers from '../pages/Carriers/Carriers';
import GetQuotes from '../pages/GetQoute/GetQuotes';
import QuickQuote from '../pages/GetQoute/QuickQuote';
import QuickQuoteRates from '../pages/GetRate/QuickQuoteRates';
import DispatchShipment from '../pages/DispatchShipment/DispatchShipment';
import BookShipmentReview from '../pages/PickupShipment/PickupShipmentLTL';
import SignIn from '../pages/ExtraPages/SignIn';
import ResetPassword from '../pages/ExtraPages/ResetPassword';
import UpdatePassword from '../pages/ExtraPages/UpdatePassword';
import LockScreenComponent from '../pages/LockScreen/LockScreen';
import ConfirmationCode from '../pages/ExtraPages/ConfirmationCode';
import ViewShipment from '../pages/ViewShipment/ViewShipment';
import KanBan from '../pages/PickupShipment/KanBan';
import Locations from '../pages/Locations/ViewLocations';
import AddLocations from '../pages/Locations/AddLocations';
import BookShipmentReviewTL from '../pages/PickupShipment/PickupShipentTL';
import AllUsers from '../pages/Users/AllUsers';
import Settings from '../pages/Settings/settings';
import ImportCsv from '../pages/ImportCsv/ImportCsv';
import CreateCarrier from '../pages/Carriers/CreateCarrier';
import CarrierManagerArea from '../components/CarrierManagerPage/CarrierManagerArea';
import BookParcelShipment from '../pages/PickupShipment/pickupShipmentParcel';
import PowerBiDashboard from '../pages/PowerBiDashboard/PowerBi';
import AppRegisteration from '../pages/AppRegisteration/AppRegisteration';
import TrackShipmentModule from '../pages/TrackShipment/TrackShipmentModule';
import UnauthComponent from '../pages/UnauthComponent/UnauthComponent';

import Notifications from '../pages/Notifications/Notifications';

import Error404 from '../pages/404Component/Error404';
import ViewLogos from '../pages/ViewLogs/ViewLogos';
import NoModuleAssigned from '../pages/NoModuleAssigned/NoModuleAssigned';
// import { Example } from '../pages/Home/Layout2/ShipmentsDemo';

// Lazy loaded components
// const SignOut = lazy(() => import('../pages/ExtraPages/SignOut'));
// const TrackShipmentDetails = lazy(() =>
//   import('../pages/TrackShipment/TrackShipmentDetails')
// );
// const MyProfile = lazy(() => import('../pages/Profile/MyProfile/MyProfile'));
// const Layout2 = lazy(() => import('../pages/Home/Layout2/Layout2'));
// const Agencies = lazy(() => import('../pages/Agencies/Agencies'));
// const Carriers = lazy(() => import('../pages/Carriers/Carriers'));
// const GetQuotes = lazy(() => import('../pages/GetQoute/GetQuotes'));
// const QuickQuote = lazy(() => import('../pages/GetQoute/QuickQuote'));
// const QuickQuoteRates = lazy(() => import('../pages/GetRate/QuickQuoteRates'));
// const DispatchShipment = lazy(() =>
//   import('../pages/DispatchShipment/DispatchShipment')
// );
// const BookShipmentReview = lazy(() =>
//   import('../pages/PickupShipment/PickupShipmentLTL')
// );
// const SignIn = lazy(() => import('../pages/ExtraPages/SignIn'));
// const ResetPassword = lazy(() => import('../pages/ExtraPages/ResetPassword'));
// const UpdatePassword = lazy(() => import('../pages/ExtraPages/UpdatePassword'));
// const LockScreenComponent = lazy(() =>
//   import('../pages/LockScreen/LockScreen')
// );
// const ConfirmationCode = lazy(() =>
//   import('../pages/ExtraPages/ConfirmationCode')
// );
// const ViewShipment = lazy(() => import('../pages/ViewShipment/ViewShipment'));
// const KanBan = lazy(() => import('../pages/PickupShipment/KanBan'));
// const Locations = lazy(() => import('../pages/Locations/ViewLocations'));
// const AddLocations = lazy(() => import('../pages/Locations/AddLocations'));
// const BookShipmentReviewTL = lazy(() =>
//   import('../pages/PickupShipment/PickupShipentTL')
// );
// const AllUsers = lazy(() => import('../pages/Users/AllUsers'));
// const Settings = lazy(() => import('../pages/Settings/settings'));
// const ImportCsv = lazy(() => import('../pages/ImportCsv/ImportCsv'));
// const CreateCarrier = lazy(() => import('../pages/Carriers/CreateCarrier'));
// const CarrierManagerArea = lazy(() =>
//   import('../components/CarrierManagerPage/CarrierManagerArea')
// );

// const BookParcelShipment = lazy(() =>
//   import('../pages/PickupShipment/pickupShipmentParcel')
// );
// const PowerBiDashboard = lazy(() =>
//   import('../pages/PowerBiDashboard/PowerBi')
// );

// const AppRegisteration = lazy(() =>
//   import('../pages/AppRegisteration/AppRegisteration')
// );

// const TrackShipmentModule = lazy(() =>
//   import('../pages/TrackShipment/TrackShipmentModule')
// );

const userRoutes = [
  // { path: '/falcon-dashboard', component:  },
  { path: '/dashboard', component: PowerBiDashboard },
  // { path: '/sigin-skeleton', component: SigninSkeleton },
  // { path: '/tms-skeleton', component: SkeletonLoader },
  // { path: '/dashboard', component: Dashboard },
  { path: '/track-shipment', component: TrackShipmentModule },
  { path: '/user-profile', component: MyProfile },
  { path: '/manage-shipments', component: Layout2 },
  // { path: '/demo-shipments', component: Example },
  { path: '/agencies', component: Agencies },
  { path: '/carriers', component: Carriers },
  { path: '/app-registration', component: AppRegisteration },
  { path: '/create-carrier', component: CreateCarrier },
  { path: '/view-locations', component: Locations },
  { path: '/add-locations', component: AddLocations },
  { path: '/get-quote', component: GetQuotes },
  { path: '/quick-quote', component: QuickQuote },
  { path: '/get-rates', component: QuickQuoteRates },
  { path: '/dispatch', component: DispatchShipment },
  { path: '/bookshipment', component: BookShipmentReview },
  { path: '/view-shipment', component: ViewShipment },
  { path: '/users', component: AllUsers },
  { path: '/kanban', component: KanBan },
  { path: '/settings', component: Settings },

  { path: '/notifications', component: Notifications },

  // { path: '/bookshipment', component: BookShipmentReview },
  { path: '/dispatchshipmentLTL', component: BookShipmentReview },
  { path: '/bookshipmentTL', component: BookShipmentReviewTL },
  { path: '/dispatchshipmentTL', component: BookShipmentReviewTL },
  { path: '/bookshipmentParcel', component: BookParcelShipment },
  { path: '/dispatchshipmentParcel', component: BookParcelShipment },

  { path: '/import-csv', component: ImportCsv },
  { path: '/', component: Layout2 },
  { path: '/not-authorized', component: UnauthComponent },
  { path: '/logs', component: ViewLogos },
  { path: '/no-module-assigned', component: NoModuleAssigned }
  // { path: '*', component: Error404 }
];

const authRoutes = [
  // { path: "/", component: SignIn },
  { path: '/agency-signin', component: SignIn },
  { path: '/agency-resetpassword', component: ResetPassword },
  { path: '/update-password', component: UpdatePassword },
  { path: '/signout', component: SignOut },
  { path: '/agency-locked-screen', component: LockScreenComponent },
  { path: '/signup', component: SignIn },
  { path: '/confirmation', component: ConfirmationCode },
  { path: '/carrier-manager', component: CarrierManagerArea }
  // { path: '*', component: Error404 }
];
export { userRoutes, authRoutes };
