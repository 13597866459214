import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export default function NoModuleAssigned() {
  return (
    <Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '15%'
        }}
      >
        <Typography variant='h6'>No Module is assinged!</Typography>
      </Box>
    </Grid>
  );
}
