import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography,
  createFilterOptions,
  OutlinedInput,
  FormHelperText,
  Chip,
  MenuItem
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  AddUpdateLocation,
  AddUpdateLocationCarrier,
  GetAgencyLocations,
  GetCarrierDetails,
  GetCarriers,
  GetLocationEProSeries,
  GetLocationsJSON,
  UpdateCarrierBillTo,
  UpdateCarrierDetails,
  UpdateLocationCarrier
} from '../../apis/Agency';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Tab from '@mui/material/Tab';
import TabContext, { useTabContext } from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NoLogo from '../../assets/carrier logos/No Logo.png';
import { base_logo_url, base_url } from '../../apis/constants';
import { MetaTags } from 'react-meta-tags';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import MaterialReactTable from 'material-react-table';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

function AddLocations(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get('shipmentId');
  const UpdatedData = props?.location?.state?.data;

  const parseAddress = address => {
    const addressParts = address.split(' ');

    // Ensure there are enough parts in the address

    // The last part will be the country code
    const countryCode = addressParts.pop();

    // The second last part will be the zip code
    const zipCode = addressParts.pop();

    // The third last part will be the state
    const state = addressParts.pop();

    // The remaining parts are the city, which is all parts before the state
    const city = addressParts.join(' ');

    return { city, state, zip: zipCode, countryCode };
  };
  const UpdatedStreetLines =
    UpdatedData?.city +
    ' ' +
    UpdatedData?.state +
    ' ' +
    UpdatedData?.postalCode +
    ' ' +
    UpdatedData?.countryCode;

  const UpdatedCityStateZipObj = {
    City: UpdatedData?.city,
    State: UpdatedData?.state,
    ZipCode: UpdatedData?.postalCode
  };

  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const history = useHistory();
  const [locationID, setLocationID] = useState(
    UpdatedData?.id ? UpdatedData?.id : null
  );
  const [locationName, setLocationName] = useState(
    UpdatedData?.name ? UpdatedData?.name : ''
  );
  const [locationAddress, setLocationAddress] = useState(
    UpdatedData?.streetLines ? UpdatedData?.streetLines : ''
  );

  const options = ['Active', 'In-Active'];
  const [agencyLocationsArray, setAgencyLocationsArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    UpdatedData?.active === false ? options[1] : options[0]
  );
  const [inputValue, setInputValue] = useState('');
  const [shipFromOpen, setShipFromOpen] = useState(false);
  const [shipCity, setShipCity] = useState([]);
  const [shipFromFinalAddress, setShipFromFinalAddress] = useState(
    UpdatedData ? UpdatedCityStateZipObj : ''
  );
  const [shipFromFinalValue, setShipFromFinalValue] = useState(
    UpdatedData ? UpdatedStreetLines : ''
  );

  const [shipFromHoveredValue, setShipFromHoveredValue] = useState('');
  const PaymentTypeArr = ['Shipper', 'Consignee', 'Third Party'];
  const [paymentTypeValue, setPaymentTypeValue] = useState(PaymentTypeArr[0]);
  const [carriers, setCarriers] = useState([]);
  const [Allcarriers, setALLcarriers] = useState([]);
  const [LTLcarriers, setLTLcarriers] = useState([]);
  const [TLcarriers, setTLcarriers] = useState([]);
  const [Tankercarriers, setTankercarriers] = useState([]);
  const [ReeferCarriers, setReeferCarriers] = useState([]);
  const [DryVanCarriers, setDryVanCarrier] = useState([]);
  const [BoxTruckCarriers, setBoxTruckCarriers] = useState([]);
  const [SprinterCarrier, setSprinterCarrier] = useState([]);
  const [InterModalCarriers, setInterModalCarriers] = useState([]);
  const [parcelCarriers, setParcelCarriers] = useState([]);
  const [shipFromloading, setShipFromloading] = useState(false);

  const [carrierTabValue, setCarrierTabValue] = useState('1');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openEProDialog, setOpenEProDialog] = useState(false);
  const [proNumberErrors, setProNumbersErrors] = useState({});
  const [proNmbChanged, setProNmbChanged] = useState(false);

  const [eproSeriesData, setEproSeriesData] = useState([]);
  const [configuredCarrierName, setConfiguredCarrierName] = useState('');
  const [configuredCarrierID, setConfiguredCarrierID] = useState(0);
  const [loadingEproSeries, setLoadingEproSeries] = useState(false);
  const [originOpenTimeValue, setOriginOpenTimeValue] = useState(
    UpdatedData?.cuttOffTime ? UpdatedData?.cuttOffTime.split(' ')[0] : 'hh:mm'
  );

  // const timeZoneOptions = [
  //   { name: 'Central Standard Time', value: 'GMT-6' },
  //   { name: 'Eastern Standard Time', value: 'GMT-5' },
  //   { name: 'Mountain Standard Time', value: 'GMT-7' },
  //   { name: 'Pacific Standard Time', value: 'GMT-8' },
  //   { name: 'Alaska Standard Time', value: 'GMT-9' },
  //   { name: 'Hawaii-Aleutian Standard Time', value: 'GMT-10' },
  // ];
  const timeZoneOptions = [
    { name: 'CST', value: 'GMT-6' },
    { name: 'EST', value: 'GMT-5' },
    { name: 'MST', value: 'GMT-7' },
    { name: 'PST', value: 'GMT-8' },
    { name: 'AST', value: 'GMT-9' },
    { name: 'H-AST', value: 'GMT-10' }
  ];
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    UpdatedData?.cuttOffTime
      ? timeZoneOptions.filter(
          tz => tz.value === UpdatedData?.cuttOffTime.split(' ')[1]
        )[0]
      : timeZoneOptions[0]
  );

  const handleOpenEProSeriesDialog = () => {
    setLoadingEproSeries(true);
    setOpenEProDialog(true);

    GetLocationEProSeries(locationID, configCarrierID)
      .then(res => {
        setEproSeriesData(res?.data?.data);
        setLoadingEproSeries(false);
      })
      .then(err => {
        setLoadingEproSeries(false);
      });
  };
  const handleCloseEProSeriesDialog = () => {
    setEproSeriesData([]);
    setOpenEProDialog(false);
  };

  const eproSeriesColumns = [
    {
      accessorKey: 'draftNumber',
      id: 'draft',
      header: 'PRO #',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
            </Box>
          )}
        </>
      )
    },
    {
      accessorKey: 'checkDigit',
      id: 'checkDigit',
      header: 'Check Digit',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
            </Box>
          )}
        </>
      )
    },
    {
      accessorKey: 'finalNumber',
      id: 'final',
      header: 'Final PRO #',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
            </Box>
          )}
        </>
      )
    },
    {
      accessorKey: 'bolNumber',
      id: 'bol',
      header: 'BOL #',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
            </Box>
          )}
        </>
      )
    },
    {
      accessorKey: 'status',
      id: 'status',
      header: 'Status',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box>
              <Chip
                sx={
                  row.original.status.toLowerCase() === 'booked'
                    ? { color: '#1b5e20', backgroundColor: '#c8e6c9' }
                    : { color: '#448aff', backgroundColor: '#bbdefb' }
                }
                label={renderedCellValue}
              />
            </Box>
          )}
        </>
      )
    }
  ];

  const handleCarrierTabChange = (event, newValue) => {
    setCarrierTabValue(newValue);
  };

  const [thirdPatyAccDetails, setThirdPatyAccDetails] = useState({
    companyName: '',
    streetLines: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: '',
    phoneNumber: '',
    emailAddress: ''
  });
  const [phoneNumber, setPhoneNumber] = useState(
    UpdatedData?.contactPhone ? UpdatedData?.contactPhone : ''
  );

  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [firstHalfCarriers, setFirstHalfCarriers] = useState([]);
  const [secondHalfCarriers, setSecondHalfCarriers] = useState([]);
  const [validattionErrors, setValidattionErrors] = useState({});
  const [carriersLoader, setCarriersLoader] = useState(false);
  const [addLocationsLoader, setAddLocationsLoader] = useState(false);
  const [addLocationCarrierArray, setAddLocationCarrierArray] = useState([]);
  const [carrierName, setCarrierName] = useState(carriers[0]);
  const [configCarrierName, setConfigCarrierName] = useState('');
  const [configCarrierID, setConfigCarrierID] = useState('');
  const [configuredCarrierArray, setConfiguredCarrierArray] = useState([]);
  const [configuredCarrierLoader, setConfiguredCarrierLoader] = useState(false);
  const [FinalConfigureCarrierArray, setFinalConfigureCarrierArray] =
    useState(null);

  const [toggleCarrier, setToggleCarrier] = useState(false);
  const [toggleCarrierIndex, setToggleCarrierIndex] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState(
    UpdatedData?.contactEmail ? UpdatedData?.contactEmail : ''
  );
  const [contactPersonName, setContactPersonName] = useState(
    UpdatedData?.contactName ? UpdatedData?.contactName : ''
  );
  const [companyName, setCompanyName] = useState(
    UpdatedData?.companyName ? UpdatedData?.companyName : ''
  );
  const [validationErrors, setValidationErrors] = useState({});

  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [agencyLocCalled, setAgencyLocCalled] = useState(false);
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.location?.state?.tab === 'LTL') {
      handleChange('event', '1');
    }
    if (props?.location?.state?.tab === 'Parcel') {
      handleChange('event', '9');
    }
  }, []);

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Locations'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleSuccesGetCarriers = (ApiRes, items, e) => {
    setToggleCarrier(false);
    if (e) {
      // successToast(
      //   `${items.name} Carrier ${
      //     e.target.checked ? 'De-Activated' : 'Activated'
      //   }`
      // );
      // setTimeout(() => {
      //   history.push('/view-locations');
      // }, 4000);
    }
    if (UpdatedData?.locationCarriers.length > 0) {
      const CarrierArray = ApiRes?.data?.data[0].locationCarriers;
      // const CarrierArray = UpdatedData?.locationCarriers;
      const LTL = CarrierArray.filter(items => items.cType === 'LTL');
      const TL = CarrierArray.filter(items => items.cType === 'TL');
      const ParcelCarriers = CarrierArray.filter(
        items => items.cType === 'Parcel'
      );
      const Tanker = CarrierArray.filter(items =>
        items.cSubType.includes('Bulk Tanker')
      );
      const Reefer = CarrierArray.filter(items =>
        items.cSubType.includes('Reefer')
      );
      const DryVan = CarrierArray.filter(items =>
        items.cSubType.includes('Dry Van')
      );
      const BoxTruck = CarrierArray.filter(items =>
        items.cSubType.includes('Box Truck')
      );
      const Sprinter = CarrierArray.filter(items =>
        items.cSubType.includes('Sprinter')
      );
      const Intermodal = CarrierArray.filter(items =>
        items.cSubType.includes('Intermodal')
      );
      const Temp = [];
      for (let index = 0; index < CarrierArray.length; index++) {
        const ApiCarrierId = CarrierArray[index].id;

        for (
          let innerIndex = 0;
          innerIndex < UpdatedData.locationCarriers.length;
          innerIndex++
        ) {
          const carrierId = UpdatedData.locationCarriers[innerIndex].carrierId;
          // const isConfigured =
          //   UpdatedData.locationCarriers[innerIndex].isConfigured;
          const scAccountNumber =
            UpdatedData.locationCarriers[innerIndex].scAccountNumber;
          const tpAccountNumber =
            UpdatedData.locationCarriers[innerIndex].tpAccountNumber;

          if (ApiCarrierId === carrierId) {
            CarrierArray[index].scAccountNumber = scAccountNumber;
            CarrierArray[index].tpAccountNumber = tpAccountNumber;
            // CarrierArray[index].isConfigured = isConfigured;
          }
        }
      }

      setCarriers(CarrierArray);
      setALLcarriers(CarrierArray);
      setLTLcarriers(LTL);
      setTLcarriers(TL);
      setTankercarriers(Tanker);
      setReeferCarriers(Reefer);
      setDryVanCarrier(DryVan);
      setBoxTruckCarriers(BoxTruck);
      setSprinterCarrier(Sprinter);
      setInterModalCarriers(Intermodal);
      setParcelCarriers(ParcelCarriers);
      setCarriersLoader(false);
      // if (items === undefined) {
      if (tabValue == '1') {
        setCarriers(LTL);
      }
      if (tabValue == '2') {
        setCarriers(TL);
      }
      if (tabValue == '3') {
        setCarriers(Tanker);
      }
      if (tabValue == '4') {
        setCarriers(Reefer);
      }
      if (tabValue == '5') {
        setCarriers(DryVan);
      }
      if (tabValue == '6') {
        setCarriers(BoxTruck);
      }
      if (tabValue == '7') {
        setCarriers(Sprinter);
      }
      if (tabValue == '8') {
        setCarriers(Intermodal);
      }
      if (tabValue == '9') {
        setCarriers(ParcelCarriers);
      }
      // }
      // if (items?.cType === 'LTL') {
      //   setCarriers(LTL);
      //   // setTabValue('1');
      // }
      // if (items?.cType === 'TL') {
      //   setCarriers(TL);
      //   // setTabValue('2');
      // }
      // if (items?.cType === 'Parcel') {
      //   setCarriers(ParcelCarriers);
      //   // setTabValue('9');
      // }
      // if (items?.cSubType.includes('Bulk Tanker')) {
      //   setCarriers(Tanker);
      //   // setTabValue('3');
      // }
      // if (items?.cSubType.includes('Reefer')) {
      //   setCarriers(Reefer);
      //   // setTabValue('4');
      // }
      // if (items?.cSubType.includes('Dry Van')) {
      //   setCarriers(DryVan);
      //   // setTabValue('5');
      // }
      // if (items?.cSubType.includes('Box Truck')) {
      //   setCarriers(BoxTruck);
      //   // setTabValue('6');
      // }
      // if (items?.cSubType.includes('Sprinter')) {
      //   setCarriers(Sprinter);
      //   // setTabValue('7');
      // }
      // if (items?.cSubType.includes('Intermodal')) {
      //   setCarriers(Intermodal);
      //   // setTabValue('8');
      // }

      const halfLength = Math.ceil(CarrierArray.length / 3);
      const firstHalf = CarrierArray?.slice(0, halfLength);
      //   setFirstHalfCarriers(firstHalf);
      const secondHalf = CarrierArray?.slice(halfLength);
      setSecondHalfCarriers(secondHalf);

      const chunkSize = Math.ceil(CarrierArray.length / 3);
      const columns = Array.from({ length: 3 }, (_, index) =>
        CarrierArray.slice(index * chunkSize, (index + 1) * chunkSize)
      );
      setFirstHalfCarriers(columns);

      const TempAddLocationCarrierArray = [];
      for (let i = 0; i < CarrierArray.length; i++) {
        TempAddLocationCarrierArray.push({
          // id: 0,
          agencyId: CarrierArray[i].agencyId,
          locationId: 0,
          carrierId: CarrierArray[i].carrierId,
          // scAccountNumber: '',
          // tpAccountNumber: '',
          active: true
        });
      }
      setAddLocationCarrierArray(TempAddLocationCarrierArray);
    } else {
      if (items !== 'GetCarriers') {
        const CarrierArray = ApiRes?.data?.data[0].locationCarriers;
        console.log(CarrierArray, 'CarrierArray1');
        const LTL = CarrierArray.filter(items => items.cType === 'LTL');
        const TL = CarrierArray.filter(items => items.cType === 'TL');
        const ParcelCarriers = CarrierArray.filter(
          items => items.cType === 'Parcel'
        );
        const Tanker = CarrierArray.filter(items =>
          items.cSubType.includes('Bulk Tanker')
        );
        const Reefer = CarrierArray.filter(items =>
          items.cSubType.includes('Reefer')
        );
        const DryVan = CarrierArray.filter(items =>
          items.cSubType.includes('Dry Van')
        );
        const BoxTruck = CarrierArray.filter(items =>
          items.cSubType.includes('Box Truck')
        );
        const Sprinter = CarrierArray.filter(items =>
          items.cSubType.includes('Sprinter')
        );
        const Intermodal = CarrierArray.filter(items =>
          items.cSubType.includes('Intermodal')
        );

        setCarriersLoader(false);
        // setCarriers(CarrierArray);
        setCarriers(CarrierArray);
        setALLcarriers(CarrierArray);
        setLTLcarriers(LTL);
        setTLcarriers(TL);
        setTankercarriers(Tanker);
        setReeferCarriers(Reefer);
        setDryVanCarrier(DryVan);
        setBoxTruckCarriers(BoxTruck);
        setSprinterCarrier(Sprinter);
        setInterModalCarriers(Intermodal);
        setParcelCarriers(ParcelCarriers);
        // if (items === undefined) {
        if (tabValue == '1') {
          setCarriers(LTL);
        }
        if (tabValue == '2') {
          setCarriers(TL);
        }
        if (tabValue == '3') {
          setCarriers(Tanker);
        }
        if (tabValue == '4') {
          setCarriers(Reefer);
        }
        if (tabValue == '5') {
          setCarriers(DryVan);
        }
        if (tabValue == '6') {
          setCarriers(BoxTruck);
        }
        if (tabValue == '7') {
          setCarriers(Sprinter);
        }
        if (tabValue == '8') {
          setCarriers(Intermodal);
        }
        if (tabValue == '9') {
          setCarriers(ParcelCarriers);
        }

        //   // setTabValue('0');
        // }
        // if (items?.cType === 'LTL') {
        //   setCarriers(LTL);
        //   // setTabValue('1');
        // }
        // if (items?.cType === 'TL') {
        //   setCarriers(TL);
        //   // setTabValue('2');
        // }
        // if (items?.cSubType === 'Bulk Tanker') {
        //   setCarriers(Tanker);
        //   // setTabValue('3');
        // }
        // if (items?.cSubType === 'Reefer') {
        //   setCarriers(Reefer);
        //   // setTabValue('4');
        // }
        // if (items?.cSubType === 'Dry Van') {
        //   setCarriers(DryVan);
        //   // setTabValue('5');
        // }
        // if (items?.cSubType === 'Box Truck') {
        //   setCarriers(BoxTruck);
        //   // setTabValue('6');
        // }
        // if (items?.cSubType === 'Sprinter') {
        //   setCarriers(Sprinter);
        //   // setTabValue('7');
        // }
        // if (items?.cSubType === 'Intermodal') {
        //   setCarriers(Intermodal);
        //   // setTabValue('8');
        // }
        // if (items?.cType === 'Parcel') {
        //   setCarriers(ParcelCarriers);
        //   // setTabValue('9');
        // }
        setCarriersLoader(false);
        const halfLength = Math.ceil(CarrierArray.length / 2);
        const firstHalf = CarrierArray?.slice(0, halfLength);
        setFirstHalfCarriers(firstHalf);
        const secondHalf = CarrierArray?.slice(halfLength);
        setSecondHalfCarriers(secondHalf);
        const TempAddLocationCarrierArray = [];
        for (let i = 0; i < CarrierArray.length; i++) {
          TempAddLocationCarrierArray.push({
            // id: 0,
            agencyId: CarrierArray[i].agencyId,
            locationId: 0,
            carrierId: CarrierArray[i].carrierId,
            // scAccountNumber: '',
            // tpAccountNumber: '',
            active: true
          });
        }
        setAddLocationCarrierArray(TempAddLocationCarrierArray);
      } else {
        const CarrierArray = ApiRes?.data?.data;
        console.log(CarrierArray, 'CarrierArray2');

        const LTL = ApiRes?.data?.data.filter(items => items.cType === 'LTL');
        const TL = ApiRes?.data?.data.filter(items => items.cType === 'TL');
        const ParcelCarriers = ApiRes?.data?.data.filter(
          items => items.cType === 'Parcel'
        );
        const Tanker = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Bulk Tanker')
        );
        const Reefer = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Reefer')
        );
        const DryVan = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Dry Van')
        );
        const BoxTruck = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Box Truck')
        );
        const Sprinter = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Sprinter')
        );
        const Intermodal = ApiRes?.data?.data.filter(items =>
          items?.cSubType?.includes('Intermodal')
        );

        setCarriersLoader(false);
        // setCarriers(CarrierArray);
        setCarriers(CarrierArray);
        setALLcarriers(CarrierArray);
        setLTLcarriers(LTL);
        setTLcarriers(TL);
        setTankercarriers(Tanker);
        setReeferCarriers(Reefer);
        setDryVanCarrier(DryVan);
        setBoxTruckCarriers(BoxTruck);
        setSprinterCarrier(Sprinter);
        setInterModalCarriers(Intermodal);
        setParcelCarriers(ParcelCarriers);
        // if (items === undefined) {
        if (tabValue == '1') {
          setCarriers(LTL);
        }
        if (tabValue == '2') {
          setCarriers(TL);
        }
        if (tabValue == '3') {
          setCarriers(Tanker);
        }
        if (tabValue == '4') {
          setCarriers(Reefer);
        }
        if (tabValue == '5') {
          setCarriers(DryVan);
        }
        if (tabValue == '6') {
          setCarriers(BoxTruck);
        }
        if (tabValue == '7') {
          setCarriers(Sprinter);
        }
        if (tabValue == '8') {
          setCarriers(Intermodal);
        }
        if (tabValue == '9') {
          setCarriers(ParcelCarriers);
        }
        // }
        // if (items?.cType === 'LTL') {
        //   setCarriers(LTL);
        //   // setTabValue('1');
        // }
        // if (items?.cType === 'TL') {
        //   setCarriers(TL);
        //   // setTabValue('2');
        // }
        // if (items?.cSubType?.includes('Bulk Tanker')) {
        //   setCarriers(Tanker);
        //   // setTabValue('3');
        // }
        // if (items?.cSubType?.includes('Reefer')) {
        //   setCarriers(Reefer);
        //   // setTabValue('4');
        // }
        // if (items?.cSubType?.includes('Dry Van')) {
        //   setCarriers(DryVan);
        //   // setTabValue('5');
        // }
        // if (items?.cSubType?.includes('Box Truck')) {
        //   setCarriers(BoxTruck);
        //   // setTabValue('6');
        // }
        // if (items?.cSubType?.includes('Sprinter')) {
        //   setCarriers(Sprinter);
        //   // setTabValue('7');
        // }
        // if (items?.cSubType?.includes('Intermodal')) {
        //   setCarriers(Intermodal);
        //   // setTabValue('8');
        // }
        // if (items?.cType === 'Parcel') {
        //   setCarriers(ParcelCarriers);
        //   // setTabValue('9');
        // }
        setCarriersLoader(false);
        const halfLength = Math.ceil(CarrierArray.length / 2);
        const firstHalf = CarrierArray?.slice(0, halfLength);
        setFirstHalfCarriers(firstHalf);
        const secondHalf = CarrierArray?.slice(halfLength);
        setSecondHalfCarriers(secondHalf);
        const TempAddLocationCarrierArray = [];
        for (let i = 0; i < CarrierArray.length; i++) {
          TempAddLocationCarrierArray.push({
            // id: 0,
            agencyId: CarrierArray[i].agencyId,
            locationId: 0,
            carrierId: CarrierArray[i].carrierId,
            // scAccountNumber: '',
            // tpAccountNumber: '',
            active: true
          });
        }
        setAddLocationCarrierArray(TempAddLocationCarrierArray);
      }
    }
  };
  const GetAllCarriers = (items, e) => {
    GetAgencyLocations(AgencyID, locationID ? locationID : items).then(res => {
      res.status === 200 && handleSuccesGetCarriers(res, items, e);
    });
  };

  useEffect(() => {
    setCarriersLoader(true);
    UpdatedData
      ? GetAllCarriers()
      : GetCarriers(AgencyID, 0).then(
          res =>
            res.status === 200 && handleSuccesGetCarriers(res, 'GetCarriers')
        );
  }, []);

  const handleUSAddresses = apiRes => {
    // let tempCity = [];
    // let tempState = [];
    // let tempZipCode = [];
    // for (let index = 0; index < USALocations.length; index++) {
    //   const City = USALocations[index].city;
    //   const State = USALocations[index].state;
    //   const ZipCode = USALocations[index].zip_code;
    //   const CountryCode = USALocations[index].country_code;

    //   tempCity.push(City + ' ' + State + ' ' + ZipCode + ' ' + CountryCode);
    //   // tempState.push(State);
    //   // tempZipCode.push(ZipCode);
    // }
    setShipFromHoveredValue(apiRes[0]);
    setShipCity(apiRes);
    // setShipState(tempState);
    // setShipZip(tempZipCode);
  };

  // useEffect(() => {
  //   handleUSAddresses();
  // }, []);

  const filterOptions = createFilterOptions({
    limit: 1000
  });

  const onShipFromChange = (event, values) => {
    let FinalObj = {};
    var filtered = [];
    if (values !== null) {
      GetLocationsJSON(values)
        .then(
          res => res.status === 200 && handleUSAddresses(res.data, 'shipFrom')
        )
        .catch(err => {
          setShipFromloading(false);
          console.log(err, 'Get Locations Error');
        });

      // filtered = shipCity.filter(option =>
      //   option?.toLowerCase().includes(values?.toLowerCase())
      // );
    }
    // setShipFromHoveredValue(filtered[0]);
    if (values !== null) {
      setValidattionErrors({
        ...validattionErrors,
        shipFromFinalValueError: null
      });

      setShipFromFinalValue(values);
      const tempData = parseAddress(values);
      const City = tempData.city;
      const State = tempData.state;
      const ZipCode = tempData.zip;
      const CountryCode = tempData.countryCode;
      FinalObj = {
        City,
        State,
        ZipCode,
        CountryCode
      };

      setShipFromFinalAddress(FinalObj);
    } else {
      //   setShipFromFinalAddress('');
      //   setShipFromFinalValue('');
      //   setShipToFinalValue('');
    }
  };

  const handleChangePaymentType = (event, newValue) => {
    if (newValue === null) {
      setPaymentTypeValue(PaymentTypeArr[0]);
    } else {
      setPaymentTypeValue(newValue);
    }
  };

  const handleChangeCarrierName = (event, newValue) => {
    if (newValue !== null) {
      setCarrierName(newValue);
    }
  };

  const handleValidations = () => {
    const errors = {};
    if (!locationName) {
      errors['locationNameError'] = 'Enter location name';
    } else if (!locationAddress) {
      errors['locationAddressError'] = 'Enter location Address';
    } else if (!shipFromFinalValue) {
      errors['shipFromFinalValueError'] = 'Enter location address';
    }
    setValidattionErrors(errors);
  };

  const handleSuccessAddedLocation = (ApiRes, FinalArray) => {
    const Id = ApiRes.data.data;
    setLocationID(Id);
    for (let i = 0; i < FinalArray.length; i++) {
      FinalArray[i].locationId = Id;
    }
    AddUpdateLocationCarrier(FinalArray)
      .then(res => res.status === 200 && GetAllCarriers(Id))
      .catch(err => err, 'FinalArrayErr');
    setAddLocationsLoader(false);
    successToast(
      UpdatedData?.id || locationID
        ? 'Location Updated successfully'
        : 'Location Added successfully'
    );
  };

  const handleErrorAddedLocation = ApiErr => {
    setAddLocationsLoader(false);
    ErrorToast(
      ApiErr?.response?.data?.reasonPhrase
        ? ApiErr?.response?.data?.reasonPhrase
        : ApiErr?.message
    );
  };

  const handleAddLocations = () => {
    handleValidations();
    const locationObj = {
      id: UpdatedData?.id ? UpdatedData?.id : locationID ? locationID : 0,
      agencyId: AgencyID,
      name: locationName,
      streetLines: locationAddress,
      city: shipFromFinalAddress?.City,
      countryCode: shipFromFinalAddress?.CountryCode,
      state: shipFromFinalAddress?.State,
      postalCode: shipFromFinalAddress?.ZipCode,
      active: inputValue === 'Active' ? true : false,
      contactName: contactPersonName,
      contactEmail: email,
      contactPhone: phoneNumber,
      companyName: companyName,
      cuttOffTime: `${originOpenTimeValue} ${selectedTimeZone.value}`,
      isHome: UpdatedData?.isHome,
      isDefault: UpdatedData?.isDefault
    };
    if (locationName && locationAddress && shipFromFinalValue) {
      setAddLocationsLoader(true);
      AddUpdateLocation(locationObj)
        .then(
          res =>
            res.status === 200 &&
            handleSuccessAddedLocation(res, addLocationCarrierArray)
        )
        .catch(err => handleErrorAddedLocation(err));
    }
  };

  const handleLocationCarrierInfoChange = (value, Apidata, index, type) => {
    // const AddUpdateLocationCarrierArray = [];
    if (type === 'Shipper / Consignee') {
      setAddLocationCarrierArray(prevArray => {
        const newArray = [...prevArray];
        newArray[index].scAccountNumber = value;
        newArray[index].carrierId = Apidata.id;
        newArray[index].active = true;
        return newArray;
      });
    }
    if (type === '3rd Party Account') {
      setAddLocationCarrierArray(prevArray => {
        const newArray = [...prevArray];
        newArray[index].tpAccountNumber = value;
        newArray[index].carrierId = Apidata.id;
        newArray[index].active = true;
        return newArray;
      });
    }
  };

  const handleClickOpen = (items, index) => {
    setCarrierTabValue('1');
    setThirdPatyAccDetails({
      companyName: items.billToCompany,
      streetLines: items.billToAddress,
      city: items.billToCity,
      state: items.billToState,
      postalCode: items.billToPostalCode,
      countryCode: items.billToCountryCode,
      phoneNumber: items.billToPhoneNumber,
      emailAddress: items.billToEmail
    });
    setIsValidEmail(true);
    setPhoneNumberValidError(false);
    handleConfigureCarrier(items, index);
    setConfigCarrierName(items.name);
    setConfigCarrierID(items.carrierId);
    setOpen(true);
  };

  const handleClose = () => {
    setConfiguredCarrierArray([]);
    setOpen(false);
    setValidattionErrors({});
  };

  const handleSuccessConfigureCarriers = ApiRes => {
    setConfiguredCarrierLoader(false);
    // handleClickOpen();
    setConfiguredCarrierArray(ApiRes?.data?.data);
    // setTabValue('1');
  };

  const handleConfigureCarrier = (items, clickedIndex, clicked) => {
    const clickedButton = carriers.find((AllCarriers, index) => index);
    setConfiguredCarrierLoader(true);
    // setConfiguredCarrierName(items.name);
    GetCarrierDetails(AgencyID, items.carrierId, locationID)
      .then(
        res => res.status === 200 && handleSuccessConfigureCarriers(res, items)
      )
      .catch(err => {
        // configuredCarrierLoader(false);
        ErrorToast(err?.message);
      });
  };

  const handleConfigureApiValidations = (values, action) => {
    const errors = {};
    if (action === 'Configure Carrier') {
      values.map(
        emptyValues =>
          emptyValues.paramKey !== 'THIRD_PARTY_AC' &&
          (errors[
            emptyValues.paramName
          ] = `Enter ${emptyValues.paramName} to configure carrier`)
      );
      setValidattionErrors(errors);
    } else {
      values.map(
        emptyValues =>
          emptyValues.paramKey !== 'THIRD_PARTY_AC' &&
          (errors[
            emptyValues.paramName
          ] = `Enter ${emptyValues.paramName} to test carrier`)
      );
      setValidattionErrors(errors);
    }
  };

  const handleConfirmConfigure = action => {
    let TempConfigureCarrierArray = [];

    const EmptyValuesArray = configuredCarrierArray
      .filter(items => items.paramKey !== 'THIRD_PARTY_AC')
      .filter(items => items.paramValue === '');
    const FinalCarrierApiArray = configuredCarrierArray.filter(
      items => items.paramValue !== ''
    );

    handleConfigureApiValidations(EmptyValuesArray, action);

    if (EmptyValuesArray.length === 0 && action === 'Configure Carrier') {
      for (let i = 0; i < FinalCarrierApiArray.length; i++) {
        const agencyId = AgencyID;
        const id = FinalCarrierApiArray[i].id;
        const carrierId = FinalCarrierApiArray[i].carrierId;
        const paramId = FinalCarrierApiArray[i].paramId;
        const paramValue = FinalCarrierApiArray[i].paramValue;
        const locationId = locationID;

        TempConfigureCarrierArray.push({
          agencyId: agencyId,
          id: id,
          carrierId: carrierId,
          paramId: paramId,
          paramValue: paramValue,
          locationId: locationId
        });
      }
      setFinalConfigureCarrierArray(TempConfigureCarrierArray);
      handleConfigureCarrierApiRequest(TempConfigureCarrierArray);
    }
  };

  const handleConfirmBillTo = () => {
    setConfiguredCarrierLoader(true);

    const finalData = {
      carrierId: configCarrierID,
      locationId: locationID,
      companyName: thirdPatyAccDetails.companyName,
      streetAddress: thirdPatyAccDetails.streetLines,
      city: thirdPatyAccDetails.city,
      state: thirdPatyAccDetails.state,
      postalCode: thirdPatyAccDetails.postalCode,
      country: thirdPatyAccDetails.countryCode,
      phone: thirdPatyAccDetails.phoneNumber,
      email: thirdPatyAccDetails.emailAddress
    };

    UpdateCarrierBillTo(finalData)
      .then(res => res.status === 200 && handleSuccessBillTo(res))
      .catch(err => ErrorToast(err?.message));
  };

  const handleSuccessConfigureCarrier = ApiRes => {
    setConfiguredCarrierLoader(false);
    handleClose();
    successToast(`${configCarrierName} Carrier Configured`);
    GetAllCarriers();

    // setTimeout(() => {
    //   history.push('/view-locations');
    // }, 4000);
  };
  const handleSuccessBillTo = ApiRes => {
    setConfiguredCarrierLoader(false);
    handleClose();
    successToast(`${configCarrierName} Bill To Configured`);
    GetAllCarriers();

    // setTimeout(() => {
    //   history.push('/view-locations');
    // }, 4000);
  };
  const handleConfigureCarrierApiRequest = TempConfigureCarrierArray => {
    setConfiguredCarrierLoader(true);

    UpdateCarrierDetails(TempConfigureCarrierArray)
      .then(res => res.status === 200 && handleSuccessConfigureCarrier(res))
      .catch(err => ErrorToast(err?.message));
  };

  const handleConfigureCarrierOnchange = (index, key, value) => {
    setValidattionErrors({ ...validattionErrors, [key]: null });
    setConfiguredCarrierArray(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        paramValue: value === true ? '1' : value === false ? '0' : value
      };
      return newData;
    });
    // setConfiguredCarrierArray(updatedArray);
  };
  const handleCarrierPhoneNumberPaste = (event, type) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    setPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handleCarrierPhoneNumberChange = (event, type) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting

    if (type === 'origin') {
      const formattedValue = inputValue
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      setPhoneNumber(formattedValue);

      // Perform validation on the formatted value
      const errors = {};
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      const isValidPhoneNumber = phoneRegex.test(formattedValue);
      if (isValidPhoneNumber) {
        setPhoneNumberValidError(false);
        setValidationErrors({ ...validationErrors, phoneNumberError: null });
      } else {
        setPhoneNumberValidError(true);
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
        setValidationErrors(errors);
      }
    }
  };
  const ValidatePhoneNumber = phNumber => {
    const inputValue = phNumber;

    const formattedValue = inputValue
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

    setThirdPatyAccDetails(oldObj => ({
      ...oldObj,
      phoneNumber: formattedValue
    }));

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber || inputValue === '') {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberErrorBT: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberErrorBT'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };

  const ValidateEmailAddress = emValue => {
    const emailRegex =
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

    const errors = {};
    setThirdPatyAccDetails(oldObj => ({
      ...oldObj,
      emailAddress: emValue
    }));

    if (emailRegex.test(emValue) || emValue === '') {
      setIsValidEmail(true);
      setValidationErrors({
        ...validationErrors,
        emailErrorBT: null
      });
    } else {
      setIsValidEmail(false);
      errors['emailErrorBT'] = 'Enter Valid Email';
    }
    setValidationErrors(errors);
  };

  const handleConfigureCarrierValues = (configuredCarrierItems, index) => {
    if (configuredCarrierItems.paramName.toLowerCase() === 'test mode') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 15
            // width: '24vw',
            // border: '1px solid',
            // borderRadius: 5
          }}
        >
          <div>{configuredCarrierItems.paramName}</div>
          <Switch
            disabled={
              (userPermissionsArr &&
                ((userPermissionsArr.filter(permissions => permissions.level3)
                  .length &&
                  !UpdatedData) ||
                  (userPermissionsArr.filter(permissions => permissions.level4)
                    .length &&
                    UpdatedData))) ||
              UserIsAdmin
                ? false
                : true
            }
            title={configuredCarrierItems.paramName}
            checked={configuredCarrierItems.paramValue === '1' ? true : false}
            onChange={e =>
              handleConfigureCarrierOnchange(
                index,
                configuredCarrierItems.paramName,
                e.target.checked
              )
            }
          />
        </div>
      );
    }

    return (
      <Grid>
        {configuredCarrierItems.paramName.toLowerCase() === 'password' ? (
          <FormControl sx={{ mt: 1, width: '100%' }} variant='outlined'>
            <InputLabel
              sx={
                Boolean(validattionErrors[configuredCarrierItems.paramName])
                  ? {
                      mt: 3,
                      color: '#d32f2f',
                      '&.Mui-focused': {
                        top: '0',
                        transform: 'translate(14px, -6px) scale(0.75)'
                      },
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                        transform: 'translate(14px, -6px) scale(0.75)'
                      },
                      top: '20%',
                      transform: 'translate(14px, -25%)'
                    }
                  : {
                      mt: 4,
                      '&.Mui-focused': {
                        top: '0',
                        transform: 'translate(14px, -6px) scale(0.75)'
                      },
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                        transform: 'translate(14px, -6px) scale(0.75)'
                      },
                      top: '20%',
                      transform: 'translate(14px, -25%)'
                    }
              }
              htmlFor='outlined-adornment-password'
            >
              Password *
            </InputLabel>
            <OutlinedInput
              error={Boolean(
                validattionErrors[configuredCarrierItems.paramName]
              )}
              disabled={
                (userPermissionsArr &&
                  ((userPermissionsArr.filter(permissions => permissions.level3)
                    .length &&
                    !UpdatedData) ||
                    (userPermissionsArr.filter(
                      permissions => permissions.level4
                    ).length &&
                      UpdatedData))) ||
                UserIsAdmin
                  ? false
                  : true
              }
              sx={{ mt: 4 }}
              size='small'
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              value={configuredCarrierItems.paramValue || ''}
              onChange={e =>
                handleConfigureCarrierOnchange(
                  index,
                  configuredCarrierItems.paramName,
                  e.target.value
                )
              }
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password * '
            />
            {Boolean(validattionErrors[configuredCarrierItems.paramName]) && (
              <FormHelperText error style={{ margin: ' 5 0 0 0' }}>
                {validattionErrors[configuredCarrierItems.paramName]}
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <TextField
            disabled={
              (userPermissionsArr &&
                ((userPermissionsArr.filter(permissions => permissions.level3)
                  .length &&
                  !UpdatedData) ||
                  (userPermissionsArr.filter(permissions => permissions.level4)
                    .length &&
                    UpdatedData))) ||
              UserIsAdmin
                ? false
                : true
            }
            error={Boolean(validattionErrors[configuredCarrierItems.paramName])}
            helperText={validattionErrors[configuredCarrierItems.paramName]}
            FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
            required={
              configuredCarrierItems.paramKey === 'THIRD_PARTY_AC'
                ? false
                : true
            }
            // fullWidth
            size='small'
            sx={{ mt: 4, width: '100%' }}
            label={configuredCarrierItems.paramName}
            defaultValue={configuredCarrierItems.paramValue}
            onChange={e =>
              handleConfigureCarrierOnchange(
                index,
                configuredCarrierItems.paramName,
                e.target.value
              )
            }
          />
        )}
      </Grid>
    );
  };

  const handleUpdateCarrier = (e, items, index) => {
    setToggleCarrierIndex(index);
    setToggleCarrier(true);
    let obj = {
      id: items.id ? items.id : 0,
      locationId: locationID ? locationID : 0,
      status: e.target.checked
    };
    UpdateLocationCarrier(obj)
      .then(res => {
        res.status === 200 && GetAllCarriers(items, e);
        // successToast(
        //   `${items.name} Carrier ${
        //     e.target.checked ? 'De-Activated' : 'Activated'
        //   }`
        // );
      })
      .catch(err => {
        console.log(err, 'err');
        ErrorToast(err?.message);
        setToggleCarrier(false);
      });
  };
  const [tabValue, setTabValue] = useState(
    props?.location?.state?.tab === 'Parcel' ? '9' : '1'
  );

  const handleChange = (event, newValue) => {
    console.log(newValue, 'newValue');
    setTabValue(newValue);
    // if (newValue === '0') {
    //   setCarriers(Allcarriers);
    // }
    if (newValue === '1') {
      setCarriers(LTLcarriers);
    }
    if (newValue === '2') {
      setCarriers(TLcarriers);
    }
    if (newValue === '3') {
      setCarriers(Tankercarriers);
    }
    if (newValue === '4') {
      setCarriers(ReeferCarriers);
    }
    if (newValue === '5') {
      setCarriers(DryVanCarriers);
    }
    if (newValue === '6') {
      setCarriers(BoxTruckCarriers);
    }
    if (newValue === '7') {
      setCarriers(SprinterCarrier);
    }
    if (newValue === '8') {
      setCarriers(InterModalCarriers);
    }
    if (newValue === '9') {
      setCarriers(parcelCarriers);
    }
  };
  const handleTimeChange = (newValue, name) => {
    console.log(newValue, 'newValue');
    if (name === 'OriginOpenTime') {
      setOriginOpenTimeValue(newValue.format('HH:mm'));
    }
  };
  const handleEmailValidation = email => {
    const emailRegex =
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

    const errors = {};
    setEmail(email);

    if (emailRegex.test(email)) {
      // setIsValidEmail(true);
      setValidationErrors({
        ...validationErrors,
        emailError: null
      });
    } else {
      // setIsValidEmail(false);
      errors['emailError'] =
        'To add multiple email addresses, please separate each address with a comma';
    }
    setValidationErrors(errors);
  };
  const handleMoveToQuickQuote = () => {
    // dispatch(setQuickQuoteRateValues(''));
    history.push('/quick-quote');
  };

  const isMobile = useIsMobile();
  return (
    <Box sx={{ flexGrow: 1, height: '85%', mt: 1 }}>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Locations | Shiptechpro'
            : 'Locations | TMS Shipping'}
        </title>
      </MetaTags>
      <ToastContainer
        position='top-center'
        style={
          isMobile
            ? { width: '100%', display: 'flex', justifyContent: 'center' }
            : { width: 'auto' }
        }
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Dialog
          fullWidth={isMobile ? true : false}
          PaperProps={{
            style: {
              minWidth: '40vw',
              minHeight: '70vh'
            }
          }}
          className='InnerDialog'
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{`Configure ${configCarrierName}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <TabContext value={carrierTabValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minWidth: '200px'
                  }}
                >
                  <TabList
                    scrollButtons='auto'
                    variant='scrollable'
                    onChange={handleCarrierTabChange}
                    aria-label='lab API tabs example'
                  >
                    <Tab label='Carrier Configurations' value='1' />
                    <Tab label='Bill To Settings' value='2' />
                    <Tab label='Carrier E-ProSeries' value='3' />
                  </TabList>
                </Box>

                <TabPanel value='1' sx={{ padding: 0 }}>
                  {configuredCarrierLoader ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10vw'
                      }}
                    >
                      <CircularProgress size={30} />
                    </Box>
                  ) : configuredCarrierArray.length === 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10vw'
                      }}
                    >
                      No data found from server!
                    </Box>
                  ) : (
                    configuredCarrierArray.map(
                      (configuredCarrierItems, index) =>
                        handleConfigureCarrierValues(
                          configuredCarrierItems,
                          index
                        )
                    )
                  )}
                </TabPanel>

                {/* fields here */}
                <TabPanel value='2' sx={{ padding: 0 }}>
                  <Box>
                    <TextField
                      // disabled={
                      //   (userPermissionsArr &&
                      //     ((userPermissionsArr.filter(permissions => permissions.level3)
                      //       .length &&
                      //       !UpdatedData) ||
                      //       (userPermissionsArr.filter(
                      //         permissions => permissions.level4
                      //       ).length &&
                      //         UpdatedData))) ||
                      //     UserIsAdmin
                      //     ? false
                      //     : true
                      // }
                      value={thirdPatyAccDetails.companyName}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Company Name'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          companyName: e.target.value
                        }))
                      }
                    />

                    <TextField
                      value={thirdPatyAccDetails.streetLines}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Address'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          streetLines: e.target.value
                        }))
                      }
                    />

                    <TextField
                      value={thirdPatyAccDetails.emailAddress}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Email'
                      size='small'
                      error={Boolean(validationErrors['emailErrorBT'])}
                      helperText={validationErrors['emailErrorBT']}
                      onChange={e => ValidateEmailAddress(e.target.value)}
                      type='email'
                    />

                    <TextField
                      value={thirdPatyAccDetails.phoneNumber}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Phone Number'
                      size='small'
                      error={Boolean(validationErrors['phoneNumberErrorBT'])}
                      helperText={validationErrors['phoneNumberErrorBT']}
                      onChange={event =>
                        ValidatePhoneNumber(event.target.value)
                      }
                      inputProps={{
                        maxLength: 10
                        // style: { fontSize: 13 } // Set the maximum length to 10 characters
                      }}
                    />

                    <TextField
                      value={thirdPatyAccDetails.city}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='City'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          city: e.target.value
                        }))
                      }
                    />

                    <TextField
                      value={thirdPatyAccDetails.state}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='State'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          state: e.target.value
                        }))
                      }
                    />

                    <TextField
                      value={thirdPatyAccDetails.postalCode}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Postal Code'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          postalCode: e.target.value
                        }))
                      }
                      type='number'
                    />

                    <TextField
                      value={thirdPatyAccDetails.countryCode}
                      fullWidth
                      sx={{ mt: 2 }}
                      label='Country'
                      size='small'
                      onChange={e =>
                        setThirdPatyAccDetails(oldObj => ({
                          ...oldObj,
                          countryCode: e.target.value
                        }))
                      }
                    />
                  </Box>
                </TabPanel>

                <TabPanel value='3' sx={{ padding: 0 }}>
                  {/* E-Pro series dialog */}
                  <Box
                    sx={{
                      mt: 15,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      variant='contained'
                      className='mt-3'
                      onClick={handleOpenEProSeriesDialog}
                    >
                      Show E-Pro Series
                    </Button>
                  </Box>
                  <Dialog
                    PaperProps={{
                      style: {
                        minWidth: '60vw',
                        height: '70vh'
                      }
                    }}
                    className='InnerDialog'
                    open={openEProDialog}
                    onClose={handleCloseEProSeriesDialog}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle id='alert-dialog-title'>
                      {configCarrierName} E-Pro Series
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        {/* <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                Changes are un-revertible until it reached to the minimum Alert
                Level
              </Grid> */}
                        <Grid
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Grid
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <MaterialReactTable
                              data={eproSeriesData}
                              columns={eproSeriesColumns}
                              enablePagination={false}
                              initialState={{
                                density: 'compact'
                              }}
                              muiTableContainerProps={{
                                sx: {
                                  minWidth: '58vw',
                                  maxWidth: '58vw',

                                  maxHeight: '43vh',
                                  minHeight: '43vh',
                                  // height: '500px',
                                  overflow: 'scroll'
                                }
                              }}
                              enableStickyHeader
                              state={{
                                isLoading: loadingEproSeries
                              }}
                              muiBottomToolbarProps={{
                                style: {
                                  minHeight: 5,
                                  maxHeight: 5
                                  // display: 'none'
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        size='small'
                        variant='outlined'
                        color='error'
                        onClick={handleCloseEProSeriesDialog}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TabPanel>
              </TabContext>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size='small' variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button
              color='info'
              size='small'
              variant='contained'
              onClick={() => handleConfirmConfigure('Test Carrier')}
            >
              Test Carrier
            </Button> */}
            <Button
              disabled={
                (userPermissionsArr &&
                  ((userPermissionsArr.filter(permissions => permissions.level3)
                    .length &&
                    !UpdatedData) ||
                    (userPermissionsArr.filter(
                      permissions => permissions.level4
                    ).length &&
                      UpdatedData))) ||
                UserIsAdmin
                  ? false
                  : true
              }
              size='small'
              variant='contained'
              color='success'
              onClick={() => {
                if (carrierTabValue === '1') {
                  handleConfirmConfigure('Configure Carrier');
                } else {
                  ValidateEmailAddress(thirdPatyAccDetails.emailAddress);
                  if (isValidEmail) {
                    ValidatePhoneNumber(thirdPatyAccDetails.phoneNumber);
                    if (!phoneNumberValidError) {
                      handleConfirmBillTo();
                    }
                  }
                }
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          sx={{
            backgroundColor: '#fff',
            p: 3
          }}
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={11}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              // alignItems: 'center'
              marginTop: -20
            }}
          >
            <Typography variant='h6'>Agency Locations</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !UpdatedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          UpdatedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  // sx={{ width: '22vw' }}
                  // fullWidth
                  size='small'
                  label='Location Name'
                  value={locationName}
                  onChange={e => {
                    setLocationName(e.target.value);
                    setValidattionErrors({
                      ...validattionErrors,
                      locationNameError: null
                    });
                  }}
                  error={Boolean(validattionErrors['locationNameError'])}
                  helperText={validattionErrors['locationNameError']}
                  FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !UpdatedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          UpdatedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  // variant='standard'
                  // FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  onChange={e => setCompanyName(e.target.value)}
                  value={companyName}
                  // required
                  // sx={{ width: '22vw' }}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  // inputProps={{ style: { fontSize: 13 } }}
                  size='small'
                  label='Company Name'
                  type='text'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !UpdatedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          UpdatedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  // sx={{ width: '22vw' }}
                  // fullWidth
                  size='small'
                  label='Location Address'
                  value={locationAddress}
                  onChange={e => {
                    setLocationAddress(e.target.value);
                    setValidattionErrors({
                      ...validattionErrors,
                      locationAddressError: null
                    });
                  }}
                  error={Boolean(validattionErrors['locationAddressError'])}
                  helperText={validattionErrors['locationAddressError']}
                  FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Grid spacing={1} container sx={{ display: 'flex' }}>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Autocomplete
                      fullWidth
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !UpdatedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              UpdatedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option}
                        </Box>
                      )}
                      filterOptions={filterOptions}
                      size='small'
                      // fullWidth
                      id='asynchronous-demo'
                      // sx={{ width: '22vw' }}
                      // open={shipFromOpen}
                      // onOpen={() => {
                      //   setShipFromOpen(true);
                      // }}
                      // onClose={() => {
                      //   setShipFromOpen(false);
                      // }}
                      // isOptionEqualToValue={(option, value) =>
                      //   option.title === value.title || option.State
                      // }
                      // getOptionLabel={option => (option ? option.title : '')}
                      options={shipCity}
                      onBlur={() => {
                        if (shipFromHoveredValue) {
                          onShipFromChange(null, shipFromHoveredValue);
                        }
                      }}
                      noOptionsText={
                        shipCity.length !== 0 ? 'Loading...' : 'No Options'
                      }
                      loading={shipFromloading}
                      // value={shipFromFinalAddress}
                      // loading={ShipFromloading}
                      value={shipFromFinalValue}
                      // onChange={onShipFromChange}
                      onChange={(event, newValue) =>
                        onShipFromChange(event, newValue)
                      }
                      onInputChange={(event, newValue) =>
                        onShipFromChange(event, newValue)
                      }
                      renderInput={params => (
                        <TextField
                          onKeyDown={e => {
                            if (e.key === 'Tab' && shipFromHoveredValue) {
                              onShipFromChange(e, shipFromHoveredValue);
                            }
                          }}
                          error={Boolean(
                            validattionErrors['shipFromFinalValueError']
                          )}
                          helperText={
                            validattionErrors['shipFromFinalValueError']
                          }
                          FormHelperTextProps={{
                            style: { margin: ' 5 0 0 0' }
                          }}
                          {...params}
                          // error={Boolean(validationErrors['shipFromErr'])}
                          // helperText={validationErrors['shipFromErr']}
                          label='City / State / Zip'
                          // InputLabelProps={{ style: { fontSize: 12 } }}
                          InputProps={{
                            //   style: {
                            //     fontSize: 12
                            //   },
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Autocomplete
                      fullWidth
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !UpdatedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              UpdatedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      size='small'
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id='controllable-states-demo'
                      options={options}
                      // sx={{ width: 200 }}
                      renderInput={params => (
                        <TextField {...params} label='Status' />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={1} sx={{ display: 'flex', mt: 1 }}>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                disabled={
                  (userPermissionsArr &&
                    ((userPermissionsArr.filter(
                      permissions => permissions.level3
                    ).length &&
                      !UpdatedData) ||
                      (userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length &&
                        UpdatedData))) ||
                  UserIsAdmin
                    ? false
                    : true
                }
                // variant='standard'
                // FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                onChange={e => setContactPersonName(e.target.value)}
                value={contactPersonName}
                // required
                // sx={{ width: '22vw' }}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                // inputProps={{ style: { fontSize: 13 } }}
                size='small'
                label='Contact Person'
                type='text'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label='Contact Phone'
                // variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['phoneNumberError'])}
                helperText={validationErrors['phoneNumberError']}
                onChange={event =>
                  handleCarrierPhoneNumberChange(event, 'origin')
                }
                value={phoneNumber}
                onPaste={event =>
                  handleCarrierPhoneNumberPaste(event, 'originPhone')
                }
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                size='small'
                type='text'
                inputProps={{
                  maxLength: 10
                  // style: { fontSize: 13 } // Set the maximum length to 10 characters
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                disabled={
                  (userPermissionsArr &&
                    ((userPermissionsArr.filter(
                      permissions => permissions.level3
                    ).length &&
                      !UpdatedData) ||
                      (userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length &&
                        UpdatedData))) ||
                  UserIsAdmin
                    ? false
                    : true
                }
                // variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['emailError'])}
                helperText={validationErrors['emailError']}
                onChange={e => handleEmailValidation(e.target.value)}
                value={email}
                // required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                // inputProps={{ style: { fontSize: 13 } }}
                // sx={{ width: '46.5vw', ml: -8.5 }}
                size='small'
                label='Email Address'
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid spacing={1} container sx={{ display: 'flex' }}>
                <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['originOpenTime'])}
                    helperText={validationErrors['originOpenTime']}
                    onChange={e => setOriginOpenTimeValue(e.target.value)}
                    value={originOpenTimeValue}
                    size='small'
                    label='Cut-Off Time'
                    type='time'
                  />

                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        marginTop: -1,
                        display: 'flex'
                        // justifyContent: 'center'
                      }}
                      components={['TimePicker', 'TimePicker']}
                    >
                      <DemoItem sx={{ width: '100%' }}>
                        {' '}
                        <TimePicker
                          onChange={newValue => {
                            handleTimeChange(newValue, 'OriginOpenTime');
                            setValidationErrors({
                              ...validationErrors,
                              originOpenTime: null
                            });
                          }}
                          label='Cut-Off Time'
                          value={dayjs(originOpenTimeValue, 'HH:mm')}
                          slotProps={{
                            textField: {
                              sx: {
                                width: '100%',
                                fontSize: '0.875rem'
                              },
                              size: 'small'
                            }
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                  <Autocomplete
                    sx={{
                      marginTop: -2
                    }}
                    fullWidth
                    disableClearable={true}
                    size='small'
                    options={timeZoneOptions}
                    value={selectedTimeZone}
                    onChange={(event, newValue) =>
                      setSelectedTimeZone(newValue)
                    }
                    getOptionLabel={option =>
                      option.name ? `${option.name} (${option.value})` : ''
                    }
                    renderOption={(props, option) => (
                      <MenuItem
                        {...props}
                        style={{ fontSize: 13 }}
                        selected={option.name === selectedTimeZone?.name}
                      >
                        {option.name} ({option.value})
                      </MenuItem>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        margin='normal'
                        label='Time Zone'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onKeyDown={e => e.preventDefault()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  // borderBottom: 1,
                  borderColor: 'divider',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                {(locationID !== null || UpdatedData?.name) && (
                  <TabList
                    onChange={handleChange}
                    aria-label='lab API tabs example'
                  >
                    {/* <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label='ALL'
                    value='0'
                  /> */}
                    <Tab
                      sx={{ textTransform: 'capitalize' }}
                      label='LTL'
                      value='1'
                    />
                    <Tab
                      sx={{ textTransform: 'capitalize' }}
                      label='Parcel'
                      value='9'
                    />
                    {/* <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label='TL'
                    value='2'
                  /> */}

                    {TLcarriers.find(types =>
                      types.cSubType.includes('Bulk Tanker')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Bulk Tanker'
                        value='3'
                      />
                    )}

                    {TLcarriers.find(types =>
                      types.cSubType.includes('Reefer')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Reefer'
                        value='4'
                      />
                    )}
                    {TLcarriers.find(types =>
                      types.cSubType.includes('Dry Van')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Dry Van'
                        value='5'
                      />
                    )}
                    {TLcarriers.find(types =>
                      types.cSubType.includes('Box Truck')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Box Truck'
                        value='6'
                      />
                    )}
                    {TLcarriers.find(types =>
                      types.cSubType.includes('Sprinter')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Sprinter'
                        value='7'
                      />
                    )}
                    {TLcarriers.find(types =>
                      types.cSubType.includes('Intermodal')
                    ) && (
                      <Tab
                        sx={{ textTransform: 'capitalize' }}
                        label='Intermodal'
                        value='8'
                      />
                    )}
                  </TabList>
                )}
                {/* {(locationID !== null || UpdatedData?.name) && (
                  <Chip
                    label={
                      <FormControlLabel
                        labelPlacement='start'
                        control={<Switch defaultChecked />}
                        label='Active-All'
                      />
                    }
                    variant='outlined'
                  />
                )} */}
              </Box>
              <TabPanel
                sx={
                  isMobile
                    ? {
                        height: '52vh',
                        overflowY: 'scroll',
                        padding: 1,
                        mt: 0
                      }
                    : { height: '52vh', overflowY: 'scroll', mt: -5 }
                }
                value={tabValue}
              >
                {' '}
                {(locationID !== null || UpdatedData?.name) && (
                  <>
                    {carriersLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '25vh'
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: 1
                        }}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          {carriers
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((items, index) => {
                              if (index % 3 === 0) {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      marginTop: 15
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex'
                                          // justifyContent: 'space-between'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={{
                                              objectFit: 'contain',
                                              height: '100%',
                                              width: 60
                                            }}
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt=''
                                          />
                                        </div>

                                        <div
                                          style={{
                                            width: '100%'
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              marginTop: 10,
                                              justifyContent: 'space-between',
                                              width: '100%',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <div>
                                              <Typography
                                                sx={{ ml: 1, fontWeight: 600 }}
                                                fontSize={17}
                                                variant='body1'
                                              >
                                                {/* <VerifiedIcon color='success' /> */}
                                                {items.name}{' '}
                                              </Typography>
                                            </div>

                                            <div>
                                              {toggleCarrier &&
                                              toggleCarrierIndex === index ? (
                                                <CircularProgress
                                                  sx={{ mr: 1 }}
                                                  size={30}
                                                  thickness={3}
                                                />
                                              ) : (
                                                items.cType !== 'TL' && (
                                                  <Switch
                                                    disabled={
                                                      (userPermissionsArr &&
                                                        ((userPermissionsArr.filter(
                                                          permissions =>
                                                            permissions.level3
                                                        ).length &&
                                                          !UpdatedData) ||
                                                          (userPermissionsArr.filter(
                                                            permissions =>
                                                              permissions.level4
                                                          ).length &&
                                                            UpdatedData))) ||
                                                      UserIsAdmin
                                                        ? false
                                                        : true
                                                    }
                                                    onClick={e =>
                                                      handleUpdateCarrier(
                                                        e,
                                                        items,
                                                        index
                                                      )
                                                    }
                                                    checked={items.active}
                                                  />
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <Typography
                                            sx={
                                              items.active
                                                ? {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                  }
                                                : {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue'
                                                  }
                                            }
                                            fontSize={12}
                                            variant='body1'
                                            onClick={() =>
                                              items.active &&
                                              handleClickOpen(items, index)
                                            }
                                          >
                                            {items?.cType !== 'TL' && (
                                              <span
                                                style={{ whiteSpace: 'nowrap' }}
                                              >
                                                {items?.isConfigured
                                                  ? 'Update Configuration'
                                                  : 'Configure'}
                                              </span>
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          {carriers
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((items, index) => {
                              if (index % 3 === 1) {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      marginTop: 15
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={{
                                              objectFit: 'contain',
                                              height: '100%',
                                              width: 60
                                            }}
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt=''
                                          />
                                        </div>
                                        <div
                                          style={{
                                            width: '100%'
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              marginTop: 10,
                                              justifyContent: 'space-between',
                                              width: '100%',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{ ml: 1, fontWeight: 600 }}
                                              fontSize={17}
                                              variant='body1'
                                            >
                                              {items.name}{' '}
                                            </Typography>

                                            {toggleCarrier &&
                                            toggleCarrierIndex === index ? (
                                              <CircularProgress
                                                sx={{ mr: 1 }}
                                                size={30}
                                                thickness={3}
                                              />
                                            ) : (
                                              items.cType !== 'TL' && (
                                                <Switch
                                                  disabled={
                                                    (userPermissionsArr &&
                                                      ((userPermissionsArr.filter(
                                                        permissions =>
                                                          permissions.level3
                                                      ).length &&
                                                        !UpdatedData) ||
                                                        (userPermissionsArr.filter(
                                                          permissions =>
                                                            permissions.level4
                                                        ).length &&
                                                          UpdatedData))) ||
                                                    UserIsAdmin
                                                      ? false
                                                      : true
                                                  }
                                                  onClick={e =>
                                                    handleUpdateCarrier(
                                                      e,
                                                      items,
                                                      index
                                                    )
                                                  }
                                                  checked={items.active}
                                                />
                                              )
                                            )}
                                          </div>
                                          <Typography
                                            sx={
                                              items.active
                                                ? {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                  }
                                                : {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue'
                                                  }
                                            }
                                            fontSize={12}
                                            variant='body1'
                                            onClick={() =>
                                              items.active &&
                                              handleClickOpen(items, index)
                                            }
                                          >
                                            {items?.cType !== 'TL' && (
                                              <span
                                                style={{ whiteSpace: 'nowrap' }}
                                              >
                                                {items?.isConfigured
                                                  ? 'Update Configuration'
                                                  : 'Configure'}
                                              </span>
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          {carriers
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((items, index) => {
                              if (index % 3 === 2) {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      marginTop: 15
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '100%'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={{
                                              objectFit: 'contain',
                                              height: '100%',
                                              width: 60
                                            }}
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt=''
                                          />
                                        </div>
                                        <div
                                          style={{
                                            width: '100%'
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              marginTop: 10,
                                              justifyContent: 'space-between',
                                              width: '100%',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{ ml: 1, fontWeight: 600 }}
                                              fontSize={17}
                                              variant='body1'
                                            >
                                              {items.name}{' '}
                                            </Typography>

                                            {toggleCarrier &&
                                            toggleCarrierIndex === index ? (
                                              <CircularProgress
                                                sx={{ mr: 1 }}
                                                size={30}
                                                thickness={3}
                                              />
                                            ) : (
                                              items.cType !== 'TL' && (
                                                <Switch
                                                  disabled={
                                                    (userPermissionsArr &&
                                                      ((userPermissionsArr.filter(
                                                        permissions =>
                                                          permissions.level3
                                                      ).length &&
                                                        !UpdatedData) ||
                                                        (userPermissionsArr.filter(
                                                          permissions =>
                                                            permissions.level4
                                                        ).length &&
                                                          UpdatedData))) ||
                                                    UserIsAdmin
                                                      ? false
                                                      : true
                                                  }
                                                  onClick={e =>
                                                    handleUpdateCarrier(
                                                      e,
                                                      items,
                                                      index
                                                    )
                                                  }
                                                  checked={items.active}
                                                />
                                              )
                                            )}
                                          </div>
                                          <Typography
                                            sx={
                                              items.active
                                                ? {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                  }
                                                : {
                                                    ml: 1,
                                                    width: '3vw',
                                                    color: 'blue'
                                                  }
                                            }
                                            fontSize={12}
                                            variant='body1'
                                            onClick={() =>
                                              items.active &&
                                              handleClickOpen(items, index)
                                            }
                                          >
                                            {items?.cType !== 'TL' && (
                                              <span
                                                style={{ whiteSpace: 'nowrap' }}
                                              >
                                                {items?.isConfigured
                                                  ? 'Update Configuration'
                                                  : 'Configure'}
                                              </span>
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 20
            }}
          >
            <Button
              sx={{ mr: 2, whiteSpace: 'nowrap' }}
              variant='outlined'
              onClick={() => {
                orderNumber
                  ? history.push({
                      pathname: '/view-locations',
                      search: `?shipmentId=${orderNumber}`,
                      state: {
                        shipmentId: orderNumber
                      }
                    })
                  : props?.location?.state?.type === 'quickQoute'
                  ? handleMoveToQuickQuote()
                  : history.push('/view-locations');
              }}
            >
              {props?.location?.state?.type === 'quickQoute'
                ? 'Back to Quick Quote'
                : 'Cancel'}
            </Button>

            {props?.location?.state?.type !== 'quickQoute' && (
              <Button
                sx={{ width: 100 }}
                variant='contained'
                onClick={handleAddLocations}
                autoFocus
                disabled={
                  (userPermissionsArr &&
                    ((userPermissionsArr.filter(
                      permissions => permissions.level3
                    ).length &&
                      !UpdatedData) ||
                      (userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length &&
                        UpdatedData))) ||
                  UserIsAdmin
                    ? false
                    : true
                }
              >
                {addLocationsLoader ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  'Save'
                )}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddLocations;
