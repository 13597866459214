import { Box } from '@material-ui/core';
import { Badge, Button, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MaterialReactTable from 'material-react-table';
import NotificationSettings from './NotificationSettings';
import { MetaTags } from 'react-meta-tags';
import { base_url } from '../../apis/constants';

import SettingsIcon from '@mui/icons-material/Settings';
import GeneralSettings from './GeneralSettings';
import { useSelector } from 'react-redux';

function Settings() {
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );

  const [userPermissionsArr, setUserPermissionsArr] = useState(null);

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Settings'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);
  const [tabValue, setTabValue] = useState(0);
  const isMobile = useIsMobile();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Settings | Shiptechpro'
            : 'Settings | TMS Shipping'}
        </title>
      </MetaTags>

      <Box
        sx={{
          borderRadius: 6
        }}
        className='bg-white w-100 mb-2 d-flex align-items-center justify-content-between px-2 pe-3'
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='scrollable tabs example'
          allowScrollButtonsMobile
          sx={{
            ml: 2,
            '& .MuiTabs-scrollButtons': {
              '&.Mui-disabled': { opacity: 0.3 }
            }
          }}
        >
          <Tab
            sx={{
              fontWeight: 600,
              fontSize: 14
            }}
            label=<div className='d-flex align-items-center'>
              <SettingsIcon />
              <div style={{ marginLeft: 3 }}>General</div>
            </div>
            {...a11yProps(0)}
          />

          <Tab
            sx={{
              fontWeight: 600,
              fontSize: 14
            }}
            label=<div className='d-flex align-items-center'>
              <NotificationsIcon />
              <div style={{ marginLeft: 3 }}>Notifications</div>
            </div>
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      {tabValue === 0 ? (
        <GeneralSettings userPermissionsArr={userPermissionsArr} />
      ) : (
        <NotificationSettings userPermissionsArr={userPermissionsArr} />
      )}
    </Box>
  );
}

export default Settings;
