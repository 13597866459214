import axios from 'axios';

export const sanitizeHTML = html => {
  if (!html) return '';

  // Check if the message contains a full HTML document
  const isFullHTML = /<!DOCTYPE html>/i.test(html);

  if (isFullHTML) {
    // Extract body content only
    const bodyMatch = html.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
    return bodyMatch ? bodyMatch[1] : 'Invalid Message Format';
  }

  return html.replace(/&nbsp;/g, ' '); // Clean up spaces in normal HTML messages
};

export const formatNotificationTime = timestamp => {
  let date = new Date(timestamp);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Month is 0-indexed
  let day = date.getDate();

  // Format date to MM/DD/YYYY
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const formatDateUTCRangeFilter = date => {
  if (!date) return '';
  const localDate = new Date(date);
  const utcDate = new Date(
    Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
  );
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = utcDate.getUTCFullYear();
  return `${year}-${month}-${day}`;
};

export const formatDateUTC = date => {
  if (!date) return '';
  const localDate = new Date(date);
  const utcDate = new Date(
    Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
  );
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const month = monthNames[utcDate.getUTCMonth()];
  const year = utcDate.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const deformatDateUTC = dDate => {
  const [date, year] = dDate.split(',');
  const month = date.slice(0, 3);
  const day = date.slice(3);

  const montth = (monthNames.indexOf(month) + 1).toString();

  return `${year.trim()}-${montth.padStart(2, 0)}-${day.trim()}`;
};

export function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber; // Return the original number if it doesn't match
}

const GOOGLE_API_KEY = 'AIzaSyBZUlA6YY8998nbFVsE_VuXZ4JpQRJwgHY';
export const getCoordinatesByAddress = async address => {
  if (!address) {
    return [];
  }
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: address,
          key: GOOGLE_API_KEY,
          components: `postal_code:${address}|country:US|country:CA|country:MX`
        }
      }
    );

    if (response.data.status === 'OK') {
      const filteredResults = response.data.results.filter(result => {
        const addressComponents = result.address_components;

        const hasZip = addressComponents.some(component =>
          component.types.includes('postal_code')
        );
        const hasCity = addressComponents.some(component =>
          component.types.includes('locality')
        );
        const hasState = addressComponents.some(component =>
          component.types.includes('administrative_area_level_1')
        );
        const hasCountry = addressComponents.some(component =>
          component.types.includes('country')
        );

        return hasZip && hasCity && hasState && hasCountry;
      });

      const formatted_address = filteredResults.map(res => ({
        address: res.formatted_address.replace(/,/g, '')
      }));
      return filteredResults[0].formatted_address.replace(/,/g, '');
    } else {
      throw new Error('Address not found');
    }
  } catch (error) {
    console.error('Geocoding Error:', error);
    return [];
  }
};

// export const getAddressByZipCode = async zipCode => {
//   try {
//     const response = await axios.get(
//       `https://maps.googleapis.com/maps/api/geocode/json`,
//       {
//         params: {
//           address: zipCode,
//           key: GOOGLE_API_KEY
//         }
//       }
//     );

//     if (response.data.status === 'OK') {
//       return response.data.results[0].formatted_address; // Returns full address
//     } else {
//       throw new Error('Zip Code not found');
//     }
//   } catch (error) {
//     console.error('Reverse Geocoding Error:', error);
//     return null;
//   }
// };
