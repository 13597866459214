import React, { Suspense, useEffect } from 'react';
import NavBar from '../CommonLayout/NavBar';
import SideBar from './SideBar';
import SignIn from '../../pages/ExtraPages/SignIn';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserAppNotifications,
  setUserDetailsObj,
  setUserToken
} from '../../Redux/UserSlice';
import { Grid } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setRateValues } from '../../Redux/GetRatesSlice';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import SkeletonLoader from '../../components/TmsLoader';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import { GetAppNotifications } from '../../apis/Agency';
// import { enqueueSnackbar, SnackbarProvider } from 'notistack';

const Layout = props => {
  const location = useLocation();
  const CurrentRoute = location.pathname;
  const selectedModuleName = localStorage.getItem('selectedModulePersist');

  var layoutMode = document.body.getAttribute('data-layout-mode');

  const history = useHistory();
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const rememberMe = useSelector(state => state.userDetails.rememberMe);
  const [userRolePermissionsArray, setUserRolePermissionsArray] =
    useState(null);
  // const UserId = useSelector(state => state.userDetails.token);
  const UserId = sessionStorage.getItem('UserAuthToken');
  const tokenExpiry = localStorage.getItem('tokenExpiryTime');

  const tokenExpiryTime = new Date(tokenExpiry);
  const currentTime = new Date();
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const [routesArray, setRoutesArray] = useState(null);

  useEffect(() => {
    // Use a Set to ensure unique values in the array
    const updatedArray = new Set();
    const routesArray = new Set();
    // Iterate through userRolePermissions
    userRolePermissions?.forEach(permission => {
      const allLevelsTrue =
        !permission.level1 &&
        !permission.level2 &&
        !permission.level3 &&
        !permission.level4 &&
        !permission.level5;

      if (!allLevelsTrue) {
        // Add the permissionName to the Set
        updatedArray.add(permission.permissionName);
        if (permission.permissionName.includes('Carriers')) {
          routesArray.add('/carriers');
        }
        if (permission.permissionName.includes('Users')) {
          routesArray.add('/users');
        }
        if (permission.permissionName.includes('Shipments')) {
          routesArray.add('/manage-shipments');
        }

        if (permission.permissionName.includes('Locations')) {
          routesArray.add('/view-locations');
        }
        if (permission.permissionName.includes('Settings')) {
          routesArray.add('/settings');
        }
        if (permission.permissionName.includes('Quick')) {
          routesArray.add('/quick-quote');
        }
      }
    });

    // Convert the Set back to an array and update the state
    setUserRolePermissionsArray(Array.from(updatedArray));
    setRoutesArray(Array.from(routesArray));
  }, [userRolePermissions]);
  const currentScreen = useLocation();
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);

  // useEffect(() => {
  //   const pathName = currentScreen?.pathname?.toLowerCase();
  //   const routeAssigned =
  //     routesArray &&
  //     routesArray.find(
  //       item => item.toLowerCase() === pathName // Compare the lowercase path name with permission
  //     );
  //   if (routesArray?.length > 0 && !routeAssigned && !UserIsAdmin) {
  //     history.push('/not-authorized');
  //   }
  // }, [routesArray]);

  useEffect(() => {
    // Function to get the value of a specific cookie by name
    const getCookieValue = cookieName => {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === cookieName) {
          return cookie[1];
        }
      }
      return null;
    };

    // Usage: Get the value of "yourCookieName"
    const yourCookieValue = getCookieValue('userInfo');
    if (yourCookieValue === null) {
      handleResetLocalStorage();
    }
  }, []);

  setTimeout(() => {
    if (tokenExpiryTime - currentTime <= 0) {
      window.location.href = '/agency-signin';
      localStorage.clear();
      localStorage.setItem('CurrentRoute', CurrentRoute);
      !rememberMe && dispatch(setPasswordRedux(''));
      !rememberMe && dispatch(setEmailRedux(''));
      dispatch(setUserToken(''));
      dispatch(setLoginAgencyID(''));
      dispatch(setRateValues(''));
      dispatch(setQuickQuoteRateValues(''));
    }
  }, tokenExpiryTime - currentTime);

  const onIdle = () => {
    setState('Idle');
    if (UserId) {
      // alert('locked');
      history.push('/agency-locked-screen', {
        currentRoute: CurrentRoute,
        selectedModuleName: selectedModuleName
      });
      // dispatch(setUserToken(''));
      // sessionStorage.setItem('UserAuthToken', null);
    }
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 10000,
    timeout: 3600000,
    throttle: 500
  });

  const toggleMode = () => {
    if (layoutMode === 'dark') {
      document.body.setAttribute('data-layout-mode', 'light');
    } else {
      document.body.setAttribute('data-layout-mode', 'dark');
    }
  };
  const queryClient = new QueryClient();
  // useEffect(() => {
  //   if (!UserIsAdmin) {
  //     assignedRoutesArray.includes(CurrentRoute)
  //       ? history.push(CurrentRoute)
  //       : history.push('/dashboard');
  //   }
  // }, []);

  const handleResetLocalStorage = () => {
    !rememberMe && dispatch(setPasswordRedux(''));
    !rememberMe && dispatch(setEmailRedux(''));
    !rememberMe && dispatch(setUserDetailsObj({}));
    localStorage.clear();
    history.push('/agency-signin', 'sessionExpired');
  };

  // useEffect(() => {
  //   if (Object.keys(cookies).length === 0) {
  //     handleResetLocalStorage();
  //   }
  // }, []);

  const isMobile = useIsMobile();

  const succRes = apiRes => {
    // const oldIds = UserNotifications?.map(notif => notif.id);
    // console.log(oldIds, 'oldIdsoldIds')
    // apiRes?.data.forEach(notif => {
    //     if (!oldIds?.includes(notif.id)) {
    // enqueueSnackbar(notif.title);
    //     }
    // });

    dispatch(
      setUserAppNotifications({
        notifications: apiRes?.data,
        count: apiRes?.pagingInfo?.pageSize
      })
    );
  };

  const fetchAppNotifications = () => {
    GetAppNotifications(true, 5)
      .then(res => succRes(res?.data))
      .catch(err => {
        console.log(err, 'appNotificationError');
      });
  };

  useEffect(() => {
    if (UserId && UserId !== '') {
      fetchAppNotifications();
      const interval = setInterval(() => {
        fetchAppNotifications();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [UserId]);
  return (
    <React.Fragment>
      {/* <QueryClientProvider client={queryClient}> */}
      {/* <Suspense> */}
      <div
        style={
          layoutMode === 'dark'
            ? { backgroundColor: '#000' }
            : {
                backgroundColor: '#F5F5F5',
                overflowX: 'hidden',
                height: 'auto',
                minHeight: '100vh'
              }
        }
      >
        {UserId && UserId !== '' ? (
          <Grid
            container
            width={'100vw'}
            sx={{ maxHeight: '100vh', position: 'relative' }}
          >
            {/* <SnackbarProvider
              maxSnack={5}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              className='bg-white text-black'
            /> */}

            <Grid
              item
              width={'6vw'}
              height={'100%'}
              style={{ maxHeight: '100vh' }}
            >
              <SideBar toggleMode={toggleMode} />
            </Grid>
            <Grid item width={isMobile ? '100vw' : '94vw'}>
              <NavBar toggleMode={toggleMode} />
              {props.children}
            </Grid>
          </Grid>
        ) : (
          <SignIn />
        )}
      </div>
      {/* </Suspense> */}
      {/* </QueryClientProvider> */}
    </React.Fragment>
  );
};

export default Layout;
